<template>
  <div>
    <b-row v-if="$route.meta.pageTitle" class="content-header mt-1 ml-2">
      <!-- Content Left -->
      <b-col class="content-header-left mb-2" cols="12" md="9">
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title pr-1 mb-0">
              {{ $route.meta.pageTitle }}
            </h2>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- Filters -->
    <ads-list-filters
      :id-query.sync="idQuery"
      :zip-query.sync="zipQuery"
      :flow-filter.sync="flowFilter"
      :type-parent-filter.sync="typeParentFilter"
      :is-enabled.sync="isEnabled"
      :min-integration-date.sync="minIntegrationDate"
      :max-integration-date.sync="maxIntegrationDate"
      :flow-options="flowOptions"
      :type-parent-options="typeParentOptions"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-1">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Montrer</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>annonces</label>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ dataMeta.from }} à {{ dataMeta.to }} sur
              {{ dataMeta.of }} résultats</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalAds"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refAdListTable"
        class="position-relative"
        :items="fetchAds"
        responsive
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Ad -->
        <template #cell(id)="row">
          <b-form-checkbox
            v-model="row.detailsShowing"
            plain
            class="vs-checkbox-con"
            @change="row.toggleDetails"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                <i class="vs-icon feather icon-check" />
              </span>
            </span>
            <span class="vs-label ml-50">{{ row.item.id }}</span>
          </b-form-checkbox>
        </template>

        <template #row-details="row">
          <b-card>
            <b-row>
              <b-col md="1">
                <b-avatar
                  v-if="!row.item.medias || !row.item.medias.length > 0"
                  :src="$config.VUE_APP_IMG_404"
                />
                <b-avatar v-else :src="row.item.medias[0].url" />
              </b-col>
              <b-col md="3">
                <strong>Title : </strong>{{ row.item.title }}
              </b-col>
              <b-col md="8">
                <strong>Description : </strong>{{ row.item.description }}
              </b-col>
              <b-col md="2"> <strong>ID : </strong>{{ row.item.id }} </b-col>
              <b-col md="2">
                <strong>Integration date : </strong
                >{{ row.item.integration_date }}
              </b-col>
              <b-col md="2">
                <strong>Update at : </strong>{{ row.item.update_at }}
              </b-col>
              <b-col md="2">
                <strong>Service : </strong>{{ row.item.service }}
              </b-col>
              <b-col md="2">
                <strong>Mandate type : </strong>{{ row.item.mandate_type }}
              </b-col>
              <b-col md="2">
                <strong>Ubiflow ID : </strong>{{ row.item.ubiflow_ID }}
              </b-col>
              <b-col md="2">
                <strong>Developer ID : </strong>{{ row.item.developer_ID }}
              </b-col>
              <b-col md="2">
                <strong>Program ID : </strong>{{ row.item.program_ID }}
              </b-col>
              <b-col md="2">
                <strong>Availability : </strong>{{ row.item.availability }}
              </b-col>
              <b-col md="2">
                <strong>Availability date : </strong
                >{{ row.item.availability_date }}
              </b-col>
              <b-col md="2">
                <strong>Reference : </strong>{{ row.item.reference }}
              </b-col>
              <b-col md="2">
                <strong>Floor : </strong>{{ row.item.floor }}
              </b-col>
              <b-col md="2">
                <strong>Nb floors : </strong>{{ row.item.nb_floors }}
              </b-col>
              <b-col md="2">
                <strong>Year of construction : </strong
                >{{ row.item.year_of_construction }}
              </b-col>
              <b-col md="2">
                <strong>Virtual visit : </strong>{{ row.item.virtual_visit }}
              </b-col>
              <b-col md="2">
                <strong>Exposure : </strong>{{ row.item.exposure }}
              </b-col>
              <b-col md="2">
                <strong>Price : </strong>{{ row.item.price }}€
              </b-col>
              <b-col md="2">
                <strong>Rent : </strong>{{ row.item.rent }}€
              </b-col>
              <b-col md="2">
                <strong>Owner type : </strong>{{ row.item.owner_type }}
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col md="2">
                <strong>Furniture price ttc : </strong
                >{{ row.item.furniture_price_ttc }}
              </b-col>
              <b-col md="2">
                <strong>Furniture price ht : </strong
                >{{ row.item.furniture_price_ht }}
              </b-col>
              <b-col md="2">
                <strong>Property price ttc : </strong
                >{{ row.item.property_price_ttc }}
              </b-col>
              <b-col md="2">
                <strong>Property price ht : </strong
                >{{ row.item.property_price_ht }}
              </b-col>
              <b-col md="2">
                <strong>Tax rate : </strong>{{ row.item.tax_rate }}
              </b-col>
              <b-col md="2">
                <strong>Profit rate : </strong>{{ row.item.profit_rate }}
              </b-col>
              <b-col md="2">
                <strong>Renovation rate : </strong
                >{{ row.item.renovation_rate }}
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col md="2">
                <strong>Price excl fees : </strong
                >{{ row.item.price_excl_fees }}
              </b-col>
              <b-col md="2">
                <strong>Payer fees : </strong>{{ row.item.payer_fees }}
              </b-col>
              <b-col md="2">
                <strong>Buyer fees : </strong>{{ row.item.buyer_fees }}
              </b-col>
              <b-col md="2">
                <strong>Seller fees : </strong>{{ row.item.seller_fees }}
              </b-col>
              <b-col md="2">
                <strong>Percentage buyer fees : </strong
                >{{ row.item.percentage_buyer_fees }}
              </b-col>
              <b-col md="2">
                <strong>Percentage seller fees : </strong
                >{{ row.item.percentage_seller_fees }}
              </b-col>
              <b-col md="2">
                <strong>Percentage seller fees : </strong
                >{{ row.item.percentage_seller_fees }}
              </b-col>
              <b-col md="2">
                <strong>Charges : </strong>{{ row.item.charges }}
              </b-col>
              <b-col md="2">
                <strong>Guarantee deposit : </strong
                >{{ row.item.guarantee_deposit }}
              </b-col>
              <b-col md="2">
                <strong>Url public tariff : </strong
                >{{ row.item.url_public_tariff }}
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col md="2">
                <strong>Area : </strong>{{ row.item.area }}
              </b-col>
              <b-col md="2">
                <strong>Stay area : </strong>{{ row.item.stay_area }}
              </b-col>
              <b-col md="2">
                <strong>Terrace area : </strong>{{ row.item.terrace_area }}
              </b-col>
              <b-col md="2">
                <strong>Balcony area : </strong>{{ row.item.balcony_area }}
              </b-col>
              <b-col md="2">
                <strong>Garden area : </strong>{{ row.item.garden_area }}
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col md="2">
                <strong>Street : </strong>{{ row.item.street }}
              </b-col>
              <b-col md="2"> <strong>Zip : </strong>{{ row.item.zip }} </b-col>
              <b-col md="2">
                <strong>City : </strong>{{ row.item.city }}
              </b-col>
              <b-col md="2">
                <strong>Country : </strong>{{ row.item.country }}
              </b-col>
              <b-col md="2">
                <strong>Latitude : </strong>{{ row.item.latitude }}
              </b-col>
              <b-col md="2">
                <strong>Longitude : </strong>{{ row.item.longitude }}
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col md="2">
                <strong>Nb rooms : </strong>{{ row.item.nb_rooms }}
              </b-col>
              <b-col md="2">
                <strong>Nb bed rooms : </strong>{{ row.item.nb_bed_rooms }}
              </b-col>
              <b-col md="2">
                <strong>Nb bathrooms : </strong>{{ row.item.nb_bathrooms }}
              </b-col>
              <b-col md="2">
                <strong>Nb water rooms : </strong>{{ row.item.nb_water_rooms }}
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col md="2">
                <strong>Kitchen : </strong>{{ row.item.kitchen }}
              </b-col>
              <b-col md="2">
                <strong>Terrace : </strong>{{ row.item.terrace }}
              </b-col>
              <b-col md="2">
                <strong>Furnished : </strong>{{ row.item.furnished }}
              </b-col>
              <b-col md="2">
                <strong>Cellar : </strong>{{ row.item.cellar }}
              </b-col>
              <b-col md="2">
                <strong>Elevator : </strong>{{ row.item.elevator }}
              </b-col>
              <b-col md="2">
                <strong>Fireplace : </strong>{{ row.item.fireplace }}
              </b-col>
              <b-col md="2">
                <strong>Veranda : </strong>{{ row.item.veranda }}
              </b-col>
              <b-col md="2">
                <strong>Garage : </strong>{{ row.item.garage }}
              </b-col>
              <b-col md="2">
                <strong>Swimming pool : </strong>{{ row.item.swimming_pool }}
              </b-col>
              <b-col md="2">
                <strong>Green spaces : </strong>{{ row.item.green_spaces }}
              </b-col>
              <b-col md="2">
                <strong>Loggia : </strong>{{ row.item.loggia }}
              </b-col>
              <b-col md="2">
                <strong>Balcony : </strong>{{ row.item.balcony }}
              </b-col>
              <b-col md="2">
                <strong>Private garden : </strong>{{ row.item.private_garden }}
              </b-col>
              <b-col md="2">
                <strong>Videocom : </strong>{{ row.item.videocom }}
              </b-col>
              <b-col md="2">
                <strong>Fibre : </strong>{{ row.item.fibre }}
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col md="2">
                <strong>Heating type : </strong>{{ row.item.heating_type }}
              </b-col>
              <b-col md="2">
                <strong>Heating energy : </strong>{{ row.item.heating_energy }}
              </b-col>
              <b-col md="2">
                <strong>Heating mechanism : </strong
                >{{ row.item.heating_mechanism }}
              </b-col>
              <b-col md="2">
                <strong>Dpe label consumption : </strong
                >{{ row.item.dpe_label_consumption }}
              </b-col>
              <b-col md="2">
                <strong>Dpe value consumption : </strong
                >{{ row.item.dpe_value_consumption }}
              </b-col>
              <b-col md="2">
                <strong>Dpe label gaz : </strong>{{ row.item.dpe_label_gaz }}
              </b-col>
              <b-col md="2">
                <strong>Dpe value gaz : </strong>{{ row.item.dpe_value_gaz }}
              </b-col>
            </b-row>

            <b-button
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails"
              class="mt-1"
            >
              Fermer
            </b-button>
          </b-card>
        </template>

        <template #cell(type_parent)="data">
          <b-badge
            pill
            :variant="`light-${resolveAdTypeParentVariant(data.value)}`"
          >
            {{ data.value }}
          </b-badge>
        </template>

        <template #cell(rent)="data">
          {{ currency(data.value) }}
        </template>

        <template #cell(charges)="data">
          {{ currency(data.value) }}
        </template>

        <template #cell(agency_fees)="data">
          {{ currency(data.value) }}
        </template>

        <template #cell(dpe_value_consumption)="row">
          {{ row.item.dpe_value_consumption }} -
          {{ row.item.dpe_label_consumption }}
        </template>

        <template #cell(dpe_value_gaz)="row">
          {{ row.item.dpe_value_gaz }} - {{ row.item.dpe_label_gaz }}
        </template>

        <template #cell(integration_date)="data">
          {{ data.value.slice(0, 10) }}
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BAvatar,
  BFormCheckbox,
  BButton,
  BBadge,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
import AdsListFilters from "../components/AdsListFilters.vue";
import useAdsListRentals from "./useAdsListRentals";
import adsStoreModule from "../adsStoreModule";
import { currency } from "@core/utils/filter";

export default {
  components: {
    AdsListFilters,

    BCard,
    BRow,
    BCol,
    BTable,
    BAvatar,
    BFormCheckbox,
    BButton,
    BBadge,
    BPagination,

    vSelect,
  },
  setup() {
    const ADS_APP_STORE_MODULE_NAME = "app-ads";

    // Register module
    if (!store.hasModule(ADS_APP_STORE_MODULE_NAME))
      store.registerModule(ADS_APP_STORE_MODULE_NAME, adsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ADS_APP_STORE_MODULE_NAME))
        store.unregisterModule(ADS_APP_STORE_MODULE_NAME);
    });

    const {
      fetchAds,
      fetchFlowsOrigins,
      tableColumns,
      idQuery,
      zipQuery,
      perPage,
      currentPage,
      totalAds,
      dataMeta,
      perPageOptions,
      searchQuery,
      minIntegrationDate,
      maxIntegrationDate,
      isEnabled,
      sortBy,
      isSortDirDesc,
      refAdListTable,
      refetchData,

      // Options
      flowOptions,
      typeParentOptions,
      serviceOptions,

      // UI
      resolveAdTypeParentVariant,

      // Extra Filters
      flowFilter,
      typeParentFilter,
    } = useAdsListRentals();

    fetchFlowsOrigins();

    return {
      fetchAds,
      tableColumns,
      idQuery,
      zipQuery,
      perPage,
      currentPage,
      totalAds,
      dataMeta,
      perPageOptions,
      searchQuery,
      minIntegrationDate,
      maxIntegrationDate,
      isEnabled,
      sortBy,
      isSortDirDesc,
      refAdListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveAdTypeParentVariant,

      flowOptions,
      typeParentOptions,
      serviceOptions,

      // Extra Filters
      flowFilter,
      typeParentFilter,

      currency,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
